import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container mx-auto text-center py-6">
        <p>Copyright © 2024 Hector Robles. All Rights Reservd</p>
      </div>
    </footer>
  );
}
